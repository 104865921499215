import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import EditInvoice from "./EditInvoice";
import { fetchInvoiceData } from "../../features/invoicesSlice/invoicesSlice";
import { useDispatch, useSelector } from "react-redux";
import { formatNumber } from "../../utils/formatNumber";
import { formatDate } from "../../utils/dateUtils";


const Finance = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showEditModal, setShowEditModal] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [clientsPerPage, setClientsPerPage] = useState(10);
    const [invData, setInvData] = useState([]);
    const [videoDetails, setVideoDetails] = useState('')

    const { allInvoiceData, status } = useSelector((state) => state.invoiceFinance);


    const handleShowDetails = (data) => {
        console.log("dataRows ==>", data);
        navigate(`/pending-invoices-details/${data?.id}`, { state: data })
    }

    useEffect(() => {
        dispatch(
            fetchInvoiceData({
                status_id: "99",
                page: currentPage,
                limit: clientsPerPage === 2000 ? "All" : clientsPerPage
            })
        );
    }, [dispatch, currentPage, clientsPerPage]);

    const handleEditeModal = (data) => {
        setVideoDetails(data)
        setShowEditModal(true)
    }

    useEffect(() => {
        if (Array.isArray(allInvoiceData?.data)) {
            setInvData(allInvoiceData.data);
        } else {
            setInvData([]); // Ensure it's always an array
        }
    }, [allInvoiceData]);


    return <>
        <div className="row">
            <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0">Digital Marketing</h4>

                    <div className="page-title-right">
                        <ol className="breadcrumb m-0">
                            <li className="breadcrumb-item">
                                <a href="javascript: void(0);">CRM</a>
                            </li>
                            <li className="breadcrumb-item">
                                <a href="javascript: void(0);">Videos</a>
                            </li>
                            <li className="breadcrumb-item active">All Videos</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-lg-12">
                <div className="card" id="upcomingVideos">
                    <div className="card-header border-0">

                        <div className="row g-4 align-items-center">

                            <div className="col-sm-auto ms-auto">
                                <div className="hstack gap-2">

                                    <button type="button" className="btn btn-info" data-bs-toggle="offcanvas"
                                        href="#offcanvasExample"><i className="ri-filter-3-line align-bottom me-1"></i> Fliters</button>


                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-body table-responsive">
                        <table id="scroll-horizontal" className="table table-bordered nowrap  table-striped align-middle"
                            style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <th>SR No.</th>
                                    <th>Action</th>
                                    <th>Video ID</th>
                                    <th>Influencer Name</th>
                                    <th>Video Live Date</th>
                                    <th>Video Status</th>
                                    <th>Payment Status</th>
                                    <th>DM Name</th>
                                    <th>Client Name</th>
                                    <th>Paid Date</th>
                                    <th>Last Video Cost</th>
                                    <th>Original Video Cost</th>
                                    <th>Channel</th>
                                    <th>Live Link</th>
                                </tr>
                            </thead>
                            <tbody>
                                {invData?.length > 0 ? (
                                    invData.map((data, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>
                                                <ul className="list-inline hstack mb-0">
                                                    <li className="list-inline-item">
                                                        <button type="button" className="btn btn-sm btn-info" onClick={() => handleShowDetails(data)}>
                                                            <i className="ri-eye-fill align-bottom"></i>
                                                        </button>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <button className="btn btn-sm btn-warning" onClick={() => handleEditeModal(data)}>
                                                            <i className="ri-edit-fill align-bottom"></i>
                                                        </button>
                                                    </li>
                                                </ul>
                                            </td>
                                            <td>{data.show_video_id}</td>
                                            <td>{data.influencer_name}</td>
                                            <td>{data.ShowVideoDate ? formatDate(data.ShowVideoDate) : "N/A"}</td>
                                            <td>
                                                <span className={`badge ${data.show_video_status === "Scheduled" ? "bg-warning" : "bg-success"}`}>
                                                    {data.show_video_status || "N/A"}
                                                </span>
                                            </td>
                                            <td>
                                                <span className={`badge ${data.show_payment_status === "Pending" ? "bg-danger" : "bg-success"}`}>
                                                    {data.show_payment_status}
                                                </span>
                                            </td>
                                            <td>{data.dmName || "N/A"}</td>
                                            <td>{data.client_name}</td>
                                            <td>{data.last_paid_date ? formatDate(data.last_paid_date) : "N.A"}</td>
                                            <td>{data.last_video_cost ? formatNumber(data.last_video_cost) : "N.A"}</td>
                                            <td>{data.original_video_cost ? formatNumber(data.original_video_cost) : "N.A"}</td>
                                            <td>{data.channel_name}</td>
                                            <td>{data.live_link ? <a href={data.live_link} target="_blank">View</a> : "N/A"}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="14" className="text-center">
                                            No Data Available
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <EditInvoice showEditModal={showEditModal} setShowEditModal={setShowEditModal} videoDetails={videoDetails} />
            </div>
        </div >

    </>;
};

export default Finance;
