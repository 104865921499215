import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { invoiceAddRemarksData } from "../../features/invoicesSlice/invoicesSlice";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";

const InvoiceRemark = (props) => {
    const { showRemark, setShowRemark, selectedDataId } = props
    const [errorData, setErrorData] = useState(false)
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        remarksTextArea: ""
    })


    const handleInputRemark = (e) => {
        const { value, name } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }
    const handleClose = () => {
        setShowRemark(false);
    }

    const handleRemarkSubmit = () => {
        const payload = {
            video_id: selectedDataId,
            remarks: formData?.remarksTextArea
        }

        try {
            dispatch(invoiceAddRemarksData(payload))
                .unwrap()
                .then((result) => {
                    if (result.status) {
                        toast.success(result.message, {
                            onClose: () => {
                            },
                        });
                        setShowRemark(false)

                    } else {
                        setErrorData(result.form_err);
                    }
                })
                .catch((err) => {
                    setErrorData(err.form_err || []);
                    toast.error(err.message);
                });
        } catch (error) {
            console.error("An error occurred:", error);
        }

    }

    return <>
        <Modal
            size="lg"
            backdrop="static"
            keyboard={false}
            show={showRemark}
            onHide={handleClose}
        >
            <Modal.Header closeButton className=" bg-light p-3">
                <Modal.Title>Add Remarks</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className="row g-3">
                    <div className="col-lg-12">
                        <div>
                            <label for="remarksTextArea" className="form-label">Remarks</label>
                            <textarea
                                className="form-control"
                                name="remarksTextArea"
                                rows={5}
                                value={formData.remarksTextArea}
                                onChange={handleInputRemark}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="hstack gap-2 justify-content-end">
                    <Button
                        variant="danger"
                        className="btn btn-light"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                    <Button
                        variant="success"
                        className="btn btn-success"
                        onClick={handleRemarkSubmit}
                    >
                        Submit
                    </Button>{" "}
                </div>
            </Modal.Footer>
        </Modal>

    </>;
};

export default InvoiceRemark;
