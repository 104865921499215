export const formatNumber = (num) => {
    if (!num) return '';

    if (num >= 10000000) {
        return (num / 10000000).toString() + 'Cr.';
    } else if (num >= 100000) {
        return (num / 100000).toString() + 'L';
    } else if (num >= 1000) {
        return (num / 1000).toString() + 'K';
    } else {
        return num.toString();
    }
};

export const parseNumber = (str) => {
    if (!str) return '';

    if (str.endsWith('K')) {
        return parseFloat(str.slice(0, -1)) * 1000;
    } else if (str.endsWith('L')) {
        return parseFloat(str.slice(0, -1)) * 100000;
    } else if (str.endsWith('Cr.')) {
        return parseFloat(str.slice(0, -3)) * 10000000;
    } else {
        return parseFloat(str);
    }
};
