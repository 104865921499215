import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { fetchInvoiceUniqueData, updateInvoiceUniqueData } from "../../features/invoicesSlice/invoicesSlice";
import { formatDate } from "../../utils/dateUtils";
import { toast, ToastContainer } from "react-toastify";
import FormattedInput from "../common/FormattedInput";
import { Link } from "react-router-dom";


const videoData = [
    { label: "Scheduled", value: 1 },
    { label: "Live", value: 2 },
    { label: "Cancelled", value: 3 },
    { label: "Partial Payment", value: 4 },
];

const paymentStatus = [
    { label: "Pending", value: 1 },
    { label: "Completed", value: 2 },
    { label: "Cancelled", value: 3 },
    { label: "Partial Paid", value: 4},
]
const advertData = [
    { label: "Innovative", value: "Innovative" },
    { label: "GeoAds", value: "GeoAds" }
]
const EditInvoice = (props) => {
    const { showEditModal, setShowEditModal, videoDetails } = props;
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({});
    const [errorData, setErrorData] = useState(false)
    const { invoiceDataDetails, invoiceDataDetails1, status, message } = useSelector(
        (state) => state.invoiceFinance
    );

    const [formData, setFormData] = useState({
        videoStatus: "",
        last_paid_date: "",
        invoiceNo: "",
        paymentStatus: "",
        advertData: "",
        taxableAmt: "",
        paidAmt: "",
        panNo: "",
        gstNo: "",
        gstAmount: "",
        bankName: "",
        acctNo: "",
        ifscCode: "",
        transactionId: "",
        lastVideoCost: "",
        originalVideoCost: "",
    });

    const handleCencel = () => {
        setShowEditModal(false)
    }

    useEffect(() => {
        if (videoDetails?.id) {
            const payload = {
                video_id: videoDetails?.id
            };
            dispatch(fetchInvoiceUniqueData(payload))
        }
    }, [dispatch, videoDetails])

    console.log(videoDetails);
    


    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };


    // For handling Select changes
    const handleSelectChange = (name, selectedOption) => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: selectedOption ? selectedOption.value : "",
        }));
    };


    const filteredVideoData = videoData.filter(option => 
        option.value === videoDetails?.video_status
    );


    useEffect(() => {
        if (videoDetails) {
            setFormData({
                videoStatus: videoDetails?.video_status || "",
                last_paid_date: "",
                invoiceNo: "",
                paymentStatus: "",
                advertData: videoDetails?.invoice_party || "",
                taxableAmt: videoDetails?.taxable_amount || "",
                paidAmt: "",
                panNo: videoDetails?.pan_no || "",
                gstNo: videoDetails?.gst_no || "",
                gstAmount: videoDetails?.gst_amt || "",
                bankName: videoDetails?.bank_name || "",
                acctNo: videoDetails?.account_no || "",
                ifscCode: videoDetails?.ifsc_code || "",
                transactionId: videoDetails?.transaction_id || "",
                //lastVideoCost: videoDetails?.last_video_cost || "",
                // originalVideoCost: videoDetails?.inf_video_cost || "",
            });
        }
    }, [videoDetails]);


    const handleSubmitUpdate = () => {

        const payload = {
            video_id: videoDetails?.id || "",
            videoStatus: formData?.videoStatus || "",
            last_paid_date: formData?.last_paid_date || "",
            invoiceNo: formData?.invoiceNo || "",
            paymentStatus: formData?.paymentStatus || "",
            advertData: formData?.advertData || "",
            taxableAmt: formData?.taxableAmt || "",
            paidAmt: formData?.paidAmt || "",
            panNo: formData?.panNo || "",
            gstNo: formData?.gstNo || "",
            gstAmount: formData?.gstAmount || "",
            bankName: formData?.bankName || "",
            acctNo: formData?.acctNo || "",
            ifscCode: formData?.ifscCode || "",
            transactionId: formData?.transactionId || "",
            remarks: formData?.remarks || "",
            lastVideoCost: invoiceDataDetails?.last_video_cost || "",
            originalVideoCost: invoiceDataDetails?.inf_video_cost || "",
        }
        try {
            dispatch(updateInvoiceUniqueData(payload))
                .unwrap()
                .then((result) => {
                    if (result.status) {
                        toast.success(result.message, {
                            onClose: () => {
                            },
                        });
                        setShowEditModal(false)

                    } else {
                        setErrorData(result.form_err);
                        toast.error(result.message);
                    }
                })
                .catch((err) => {
                    setErrorData(err.form_err || []);
                    toast.error(err.message);
                });
        } catch (error) {
            console.error("An error occurred:", error);
        }

    }


    // const mapErrors = (errorData) => {
    //     const errorMap = {};
    //     errorData.forEach((error) => {
    //         if (!errorMap[error.param]) {
    //             errorMap[error.param] = error.msg;
    //         }
    //     });
    //     return errorMap;
    // };

    // useEffect(() => {
    //     const errorsValue = mapErrors(errorData);
    //     setErrors(errorsValue);
    // }, [errorData]);

    useEffect(() => {
        console.log("invoiceDataDetails?.invoiceList ==>", invoiceDataDetails1);

    }, [invoiceDataDetails1])
    return <>
        <Modal
            // className="modal-dialog modal-xl"
            size="xl"
            backdrop="static"
            keyboard={false}
            show={showEditModal}
            onHide={handleCencel}
        >
            <Modal.Header closeButton className=" bg-light p-3">
                <Modal.Title>Edit Invoice</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className="modal-body">
                    <div className="row g-3">
                        <div className="col-lg-4">
                            <div>
                                <label for="video-id" className="form-label">Video ID<span className="text-danger">*</span></label>
                                <input type="text" id="video-id" className="form-control" placeholder="Enter Influencer Name"
                                    value={invoiceDataDetails?.show_video_id} disabled />
                            </div>
                        </div>
                        
                        <div className="col-lg-4">
                            <div>
                                <label for="product-type" className="form-label">Product Type<span className="text-danger">*</span></label>
                                <input type="text" className="form-control" placeholder="" value={invoiceDataDetails?.product ? JSON.parse(invoiceDataDetails.product).label : ""} disabled />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div>
                                <label for="influencer-name" className="form-label">Influencer Name<span
                                    className="text-danger">*</span></label>
                                <input type="text" id="influencer-name" className="form-control" value={invoiceDataDetails?.influencer_name} disabled />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div>
                                <label for="client-name" className="form-label">Client Name <span className="text-danger">*</span></label>
                                <input type="text" id="client-name" className="form-control" value={invoiceDataDetails?.client_name} disabled />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div>
                                <label for="video-live-date" className="form-label">Video Live Date<span
                                    className="text-danger">*</span></label>
                                <input type="text" id="video-live-date" className="form-control" value={formatDate(invoiceDataDetails?.live_date)} disabled />
                            </div>
                        </div>
                        
                        
                        <div className="col-lg-4">
                            <div>
                                <label for="channel-type" className="form-label">Channel Type</label>
                                <input type="text" id="channel-type" className="form-control" value={invoiceDataDetails?.channel_name} disabled />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div>
                                <label for="live-link" className="form-label">Live Link <span className="text-danger">*</span></label>
                                <input type="text" id="live-link" className="form-control" value={invoiceDataDetails?.video_link}
                                    disabled />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div>
                                <label for="utm-link" className="form-label">UTM Link<span className="text-danger">*</span></label>
                                <input type="text" id="utm-link" className="form-control" value={invoiceDataDetails?.video_link} disabled />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div>
                                <label for="link-in-bio" className="form-label">Link in Bio<span className="text-danger">*</span></label>
                                <input type="text" id="link-in-bio" className="form-control" value={invoiceDataDetails?.video_link} disabled />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div>
                                <label for="payment-status" className="form-label">Video Status <span
                                    className="text-danger">*</span></label>
                                <Select
                                    name="videoStatus"
                                    options={filteredVideoData}
                                    classNamePrefix="select"
                                    value={filteredVideoData.find((option) => option.value === formData.videoStatus) || ""}
                                    onChange={(selectedOption) => handleSelectChange("videoStatus", selectedOption)}

                                />
                                {/* <div className="text-danger" > {errors.videoStatus && <> {errors.videoStatus}</>}</div> */}

                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div>
                                <label for="pan-no-field" className="form-label">PAN No <span className="text-danger">*</span></label>
                                <input type="text" id="pan-no-field" className="form-control" name="panNo" value={formData?.panNo} onChange={handleChange} placeholder="Enter PAN No" />
                                {/* <div className="text-danger" > {errors.panNo && <> {errors.panNo}</>}</div> */}

                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div>
                                <label for="gst-no-field" className="form-label">GST No <span className="text-danger">*</span></label>
                                <input type="text" id="igst-no-field" className="form-control" name="gstNo" value={formData?.gstNo} onChange={handleChange} placeholder="Enter GST No" required="" />
                                {/* <div className="text-danger" > {errors.gstNo && <> {errors.gstNo}</>}</div> */}

                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div>
                                <label for="gst-no-field" className="form-label">GST Amount <span className="text-danger">*</span></label>
                                <input type="number" id="igst-no-field" className="form-control" name="gstAmount" value={formData?.gstAmount} onChange={handleChange} placeholder="Enter GST Amount" required="" />
                                {/* <div className="text-danger" > {errors.gstAmount && <> {errors.gstAmount}</>}</div> */}

                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div>
                                <label for="bank-name-field" className="form-label">Bank Name <span className="text-danger">*</span></label>
                                <input type="text" id="bank-name-field" className="form-control" name="bankName" value={formData?.bankName} onChange={handleChange} placeholder="Enter Bank Name" />
                                {/* <div className="text-danger" > {errors.bankName && <> {errors.bankName}</>}</div> */}

                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div>
                                <label for="account-no-field" className="form-label">Account No <span
                                    className="text-danger">*</span></label>
                                <input type="text" id="account-no-field" className="form-control" name="acctNo" value={formData?.acctNo} onChange={handleChange} placeholder="Enter Account No" />
                                {/* <div className="text-danger" > {errors.acctNo && <> {errors.acctNo}</>}</div> */}

                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div>
                                <label for="ifsc-code-field" className="form-label">IFSC Code <span className="text-danger">*</span></label>
                                <input type="text" id="ifsc-code-field" className="form-control" name="ifscCode" value={formData?.ifscCode} onChange={handleChange} placeholder="Enter IFSC Code" />
                                {/* <div className="text-danger" > {errors.ifscCode && <> {errors.ifscCode}</>}</div> */}

                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div>
                                <label for="paid-date" className="form-label">Paid Date</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    placeholder="Select Date"
                                    onChange={handleChange}
                                    name="last_paid_date"
                                    value={formData.last_paid_date || ""}
                                />
                                {/* <div className="text-danger" > {errors.last_paid_date && <> {errors.last_paid_date}</>}</div> */}

                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div>
                                <label for="invoice-no" className="form-label">Invoice No <span className="text-danger">*</span></label>
                                <input type="text" name="invoiceNo" className="form-control" placeholder="Invoice No" onChange={handleChange} value={formData?.invoiceNo || ""} />
                                {/* <div className="text-danger" > {errors.invoiceNo && <> {errors.invoiceNo}</>}</div> */}

                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div>
                                <label for="payment-status" className="form-label">Payment Status <span className="text-danger">*</span></label>
                                <Select
                                    name="paymentStatus"
                                    options={paymentStatus}
                                    classNamePrefix="paymentStatus"
                                    value={paymentStatus.find((option) => option.value === formData.paymentStatus) || ""}
                                    onChange={(selectedOption) => handleSelectChange("paymentStatus", selectedOption)}
                                />
                                {/* <div className="text-danger" > {errors.paymentStatus && <> {errors.paymentStatus}</>}</div> */}

                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div>
                                <label for="payment-status" className="form-label">Invoice Party <span
                                    className="text-danger">*</span></label>
                                <Select
                                    name="advertData"
                                    options={advertData}
                                    classNamePrefix="advertData"
                                    value={advertData.find((option) => option.value === formData.advertData) || ""}
                                    onChange={(selectedOption) => handleSelectChange("advertData", selectedOption)}
                                />
                                {/* <div className="text-danger" > {errors.advertData && <> {errors.advertData}</>}</div> */}

                            </div>
                        </div>
                        
                        <div className="col-lg-4">
                            <div>
                                <label for="taxable-amount" className="form-label">Taxable Amount <span
                                    className="text-danger">*</span></label>
                                <FormattedInput type="number" id="taxable-amount" className="form-control" name="taxableAmt" value={formData?.taxableAmt} onChange={handleChange} placeholder="Enter Taxable Amount" />
                                <div className="text-danger" > {errors.taxableAmt && <> {errors.taxableAmt}</>}</div>

                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div>
                                <label for="paid-amount" className="form-label">Paid Amount <span className="text-danger">*</span></label>
                                <FormattedInput type="number" id="paid-amount" className="form-control" name="paidAmt" value={formData?.paidAmt} onChange={handleChange} placeholder="Enter Paid Amount" />
                                <div className="text-danger" > {errors.paidAmt && <> {errors.paidAmt}</>}</div>

                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div>
                                <label for="last-video-cost" className="form-label">Last Video Cost <span
                                    className="text-danger">*</span></label>
                                <FormattedInput type="text" id="last-video-cost" className="form-control" name="lastVideoCost" value={invoiceDataDetails?.last_video_cost} onChange={() => {}}  />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div>
                                <label for="original-video-cost" className="form-label">Original Video Cost <span
                                    className="text-danger">*</span></label>
                                <FormattedInput type="text" id="original-video-cost" className="form-control" name="originalVideoCost" value={invoiceDataDetails?.inf_video_cost} onChange={() => {}}  />
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div>
                                <label for="transaction-id" className="form-label">Transaction ID <span
                                    className="text-danger">*</span></label>
                                <div id="transaction-id-container" className="row g-3">
                                    <div className="col-lg-4 transaction-id-field d-flex align-items-center">
                                        <input type="number" className="form-control transaction-id-input" name="transactionId" value={formData?.transactionId} onChange={handleChange} placeholder="Transaction ID" />
                                        {/* <div className="text-danger" > {errors.transactionId && <> {errors.transactionId}</>}</div> */}


                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div>
                                <label for="remarks" className="form-label">Remarks</label>
                                <textarea className="form-control" name="remarks" id="remarks" value={formData?.remarks} onChange={handleChange} rows="2"></textarea>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="row">
                                <label htmlFor="remarks" className="form-label">Invoices</label>
                                {invoiceDataDetails1?.map((invData, index) => (
                                    <div className="col-4" key={index}>
                                        <div className="d-flex border border-dashed p-2 rounded position-relative">
                                            <div className="flex-shrink-0">
                                                <i className="ri-file-pdf-line fs-17 text-danger"></i>
                                            </div>
                                            <div className="flex-grow-1 ms-2">
                                                <h6>
                                                    <Link to={`https://backapi.influenceract.com/uploads/${invData?.fileName}`} target="_blank" className="stretched-link">
                                                        {invData?.fileName}
                                                    </Link>
                                                </h6>
                                                <small>685 KB</small>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>


                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="hstack gap-2 justify-content-end">
                    <Button
                        variant="danger"
                        className="btn btn-light"
                        onClick={handleCencel}
                    >
                        Close
                    </Button>
                    <Button
                        variant="success"
                        className="btn btn-success"
                        onClick={handleSubmitUpdate}
                    >
                        Update
                    </Button>{" "}
                </div>
            </Modal.Footer>
        </Modal>
    </>;
};

export default EditInvoice;
