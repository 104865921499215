import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ClientsNavbar from './ClientsNavbar';
import DmNavbar from './DmNavbar';
import DmexeNavbar from './DmexeNavbar';
import SalesNavbar from './SalesNavbar';
import SalesexeNavbar from './SalesexeNavbar';
import FinanceNavbar from './FinanceNavbar';




// import './Sidebar.css'; // Assuming your CSS is in Sidebar.css

const Sidebar = (props) => {
  const { toggleSidebar, isSidebarOpen, isOpen, decodedToken } = props
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleToggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };


  return (
    <>
      <aside className={`app-menu navbar-menu ${isCollapsed ? 'collapsed' : ''} ${isSidebarOpen ? 'open' : ''}`}>
        <div className="navbar-brand-box">
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img src="../assets/images/fav.png" alt="" height="22" />
            </span>
            <span className="logo-lg">
              <img src="../assets/images/logo-light.png" alt="" height="40" />
            </span>
          </Link>
          <Link to="/" className="logo logo-light">
            <span className="logo-sm">
              <img src="../assets/images/fav.png" alt="" height="22" />
            </span>
            <span className="logo-lg">
              <img src="../assets/images/logo-light.png" alt="" height="40" />
            </span>
          </Link>
          <button
            type="button"
            className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
            id="vertical-hover"
            onClick={handleToggleSidebar}
          >
            <i className="ri-record-circle-line-open"></i>
          </button>
        </div>

        <div id="scrollbar">
          <div className="container-fluid">
            <div id="two-column-menu"></div>
            <ul className="navbar-nav" id="navbar-nav">
              <li className="menu-title">
                <i className="ri-more-fill"></i>
                <span data-key="t-pages">Menu</span>
              </li>
              <li className="nav-item">
                <Link className="nav-link menu-link" to="/dashboard">
                  <i className="ri-dashboard-2-line"></i>
                  <span data-key="t-dashboard">Dashboard</span>
                </Link>
              </li>
              {
                decodedToken?.UsrRoleName === "Sales Team Lead" ?
                  <>
                    <SalesNavbar />
                  </>
                  : decodedToken?.UsrRoleName === "Sales" ?
                    <>
                      <SalesexeNavbar />
                    </>
                    : decodedToken?.UsrRoleName === "Client" ?
                      <>
                        <ClientsNavbar />
                      </>
                      : decodedToken?.UsrRoleName === "Digital Marketing Team Lead" ?
                        <>
                          <DmNavbar />
                        </>
                        : decodedToken?.UsrRoleName === "Digital Marketing" ?
                          <>
                            <DmexeNavbar />
                          </>
                          : decodedToken?.UsrRoleName === "Finance" &&
                          <>
                            <FinanceNavbar />
                          </>
              }
            </ul>
          </div>
        </div>

        <div className="sidebar-background"></div>
      </aside>
    </>
  );
};

export default Sidebar;
