import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axiosInstance";

// Create an async thunk for fetching data
export const fetchInvoiceData = createAsyncThunk(
    "invoice/fetchInvoiceData",
    async ({ status_id, formData, page, limit }, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post("/finance/invoice_list", {
                status_id,
                page,
                limit
            });
            return { status_id, data: response.data };
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const fetchInvoiceUniqueData = createAsyncThunk(
    "invoice/fetchInvoiceUniqueData",
    async (payload, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post("/finance/video_details", payload);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const updateInvoiceUniqueData = createAsyncThunk(
    "invoice/updateInvoiceUniqueData",
    async (payload, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post("/finance/payment_data_store", payload);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const invoiceRemarkData = createAsyncThunk(
    "invoice/invoiceRemarkData",
    async (payload, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post("/finance/show_remark", payload);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);


export const invoiceDocumentskData = createAsyncThunk(
    "invoice/invoiceDocumentskData",
    async (payload, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post("/finance/invoice_by_video", payload);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);


export const invoiceAddRemarksData = createAsyncThunk(
    "invoice/invoiceAddRemarksData",
    async (payload, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post("/finance/add_remark_by_finance_for_dm", payload);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const addInvoiceRemark = createAsyncThunk(
    "invoice/addInvoiceRemark",
    async (payload, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post("/video_script/add_remark_by_dm_for_finance", payload);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

const initialInvoiceState = {
    data: [],
    pagination: {
        limit: 0,
        page: 0,
        totalItems: 0,
        totalPages: 0
    }
};

const invoiceSlice = createSlice({
    name: "invoice",
    initialState: {
        allInvoiceData: { ...initialInvoiceState },
        activeInvoiceData: { ...initialInvoiceState },
        pendingInvoiceData: { ...initialInvoiceState },
        inactiveInvoiceData: { ...initialInvoiceState },
        status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchInvoiceData.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchInvoiceData.fulfilled, (state, action) => {
                const { status_id, data } = action.payload;
                state.status = "succeeded";
                if (status_id === "1") {
                    state.activeInvoiceData = data;
                } else if (status_id === "0") {
                    state.pendingInvoiceData = data;
                } else if (status_id === "4") {
                    state.inactiveInvoiceData = data;
                } else if (status_id === "99") {
                    state.allInvoiceData = data;
                }
            })
            .addCase(fetchInvoiceData.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(fetchInvoiceUniqueData.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchInvoiceUniqueData.fulfilled, (state, action) => {
                const { data, invoiceList, payment_data } = action.payload;
                state.status = "succeeded";
                state.invoiceDataDetails = data;
                state.invoiceDataDetails1 = invoiceList;
                state.invoicePaymentData = payment_data;
            })
            .addCase(fetchInvoiceUniqueData.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(updateInvoiceUniqueData.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateInvoiceUniqueData.fulfilled, (state, action) => {
                const { data } = action.payload;
                state.status = "succeeded";
                state.invoiceAddedDetails = data;
            })
            .addCase(updateInvoiceUniqueData.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(invoiceRemarkData.pending, (state) => {
                state.status = "loading";
            })
            .addCase(invoiceRemarkData.fulfilled, (state, action) => {
                const { data } = action.payload;
                state.status = "succeeded";
                state.invoiceRemarkDetails = data;
            })
            .addCase(invoiceRemarkData.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(invoiceDocumentskData.pending, (state) => {
                state.status = "loading";
            })
            .addCase(invoiceDocumentskData.fulfilled, (state, action) => {
                const { data } = action.payload;
                state.status = "succeeded";
                state.invoiceDocumentsDetails = data;
            })
            .addCase(invoiceDocumentskData.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(invoiceAddRemarksData.pending, (state) => {
                state.status = "loading";
            })
            .addCase(invoiceAddRemarksData.fulfilled, (state, action) => {
                const { data } = action.payload;
                state.status = "succeeded";
                state.invoiceAddRemarkssDetails = data;
            })
            .addCase(invoiceAddRemarksData.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(addInvoiceRemark.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addInvoiceRemark.fulfilled, (state, action) => {
                const { data } = action.payload;
                state.status = "succeeded";
                state.invoiceAddedRemarks = data;
            })
            .addCase(addInvoiceRemark.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            });
    }
});

export default invoiceSlice.reducer;
