import React, { useState } from 'react'
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { addInvoiceRemark } from '../../features/invoicesSlice/invoicesSlice';

const UploadInvoice = ({ showInvoice, setShowInvoice, selectedDataId }) => {
    const [formData, setFormData] = useState({
        remarkData: "",
        fileName: null,
    })
    const dispatch = useDispatch()
    const [ToastDisplayed, setToastDisplayed] = useState(false)


    const handleClose = () => {
        setShowInvoice(false)
    }

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        if (type === 'file') {
            setFormData((prevData) => ({
                ...prevData,
                [name]: files[0], // Save the file object
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value, // Save the text value
            }));
        }
    };

    const handleSubmitRemark = (e) => {
        e.preventDefault();

        const formdata = new FormData();
        if (formData.fileName) {
            formdata.append("fileName", formData.fileName);
        }
        const payloadData = {
            script_id: selectedDataId,
            remarks: formData.remarkData,

        };
        formdata.append("payloadData", JSON.stringify(payloadData));

        // Dispatch the action
        dispatch(addInvoiceRemark(formdata))
            .unwrap()
            .then((result) => {
                if (result.status) {
                    setToastDisplayed(true);
                    toast.success(result.message, {
                        onClose: () => {
                            setFormData({
                                assignTo: "",
                                remarkData: "",
                                fileName: null, // Reset file
                            });
                        }
                    });
                    handleClose();
                }
            });
    };


    return (
        <>
            <Modal
                size="lg"
                backdrop="static"
                keyboard={false}
                show={showInvoice}
                onHide={handleClose}
            >
                <Modal.Header closeButton className=" bg-light p-3">
                    <Modal.Title>Upload Invoice</Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-body">

                    <div class="row g-3">

                        <div class="col-lg-12">
                            <div>
                                <label for="to-person" class="form-label">Upload Invoice: <span class="text-danger">*</span></label>
                                <input class="form-control" type="file" name="fileName" onChange={handleChange} id="formFile" />
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div>
                                <label for="remarksTextArea" class="form-label">Remarks</label>
                                <textarea
                                    className="form-control"
                                    id="remarksTextArea"
                                    rows="5"
                                    name="remarkData"
                                    value={formData.remarkData}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                    </div>


                </Modal.Body>
                <Modal.Footer>
                    <div className="hstack gap-2 justify-content-end">
                        <Button
                            variant="danger"
                            className="btn btn-light"
                            onClick={handleClose}
                        >
                            Close
                        </Button>
                        <Button
                            variant="success"
                            className="btn btn-success"
                            onClick={handleSubmitRemark}
                        >
                            Submit
                        </Button>{" "}
                    </div>
                </Modal.Footer>
            </Modal >
        </>
    )
}

export default UploadInvoice
