import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { clientUpdateVideoData } from "../../features/clientInfluencerRequestSlice/clientInfluencerRequestSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Required for Toastify styles
import FormattedInputYouTube from "./FormattedInputYouTube";
import { fetchCategoryData } from "../../features/commonSlice/commonSlice";
import FormattedInput from "./FormattedInput";

const videoStatusList = [
  { value: 1, label: "Scheduled" },
  { value: 2, label: "Live" },
  { value: 3, label: "Cancelled" }
];
const CreateVideoDM = (props) => {
  const {
    showEditModal,
    selectedRowsData,
    setShowEditModal
  } = props;
  const dispatch = useDispatch();

  const { categoryData, status: commonStatus } = useSelector(
    (state) => state.influencer
  );
  const [errorData, setErrorData] = useState(false);

  const [categoryList, setCategoryList] = useState([]);
  const [dataForm, setDataForm] = useState({
    productName: { label: "", value: "" },
    videoStatus: { label: "", value: "" },
    tentativeDate: "",
    fileName: "",
    finance_remark: "",
    inf_video_cost: "",
    inf_final_video_cost: "",
    scheduled_date: "",
    live_date: "",
    video_link: ""
  });

  const [errors, setErrors] = useState("");


  useEffect(() => {
    console.log("selectedRowsData ===>", selectedRowsData);
    if (selectedRowsData) {
      setDataForm({
        productName: selectedRowsData.product ? JSON.parse(selectedRowsData.product) : { label: "", value: "" },
        videoStatus: videoStatusList.find((status) => status.value === selectedRowsData.video_status) || { label: "", value: "" },
        tentativeDate: selectedRowsData.tentative_date ? new Date(selectedRowsData.tentative_date).toISOString().split("T")[0] : "",
        inf_video_cost: selectedRowsData.inf_video_cost || "",
        inf_final_video_cost: selectedRowsData.inf_final_video_cost || "",
        scheduled_date: selectedRowsData.scheduled_date ? new Date(selectedRowsData.scheduled_date).toISOString().split("T")[0] : "",
        live_date: selectedRowsData.live_date ? new Date(selectedRowsData.live_date).toISOString().split("T")[0] : "", // Initialize live_date
        video_link: selectedRowsData.video_link || ""
      });
    }
  }, [selectedRowsData])

  useEffect(() => {
    dispatch(fetchCategoryData());
  }, []);

  useEffect(() => {
    if (commonStatus === "succeeded") {
      setCategoryList(categoryData);
    }
  }, [commonStatus, categoryData]);

  const handleSelectChange = (name, value) => {
    setDataForm({
      ...dataForm,
      [name]: value
    });
  };
  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === 'file') {
      setDataForm((prevData) => ({
        ...prevData,
        [name]: files[0], // Save the file object
      }));
    } else {
      setDataForm((prevData) => ({
        ...prevData,
        [name]: value
      }));
    }
  };



  const handelCreateVideo = () => {
    const formdata = new FormData();
    // Append the file if present
    if (dataForm.fileName) {
      formdata.append("fileName", dataForm.fileName);  // Appending the file object to FormData
    }
    const payloadData = {
      script_id: selectedRowsData?.id,
      video_status: dataForm?.videoStatus?.value
            ? { value: dataForm?.videoStatus?.value, label: dataForm?.videoStatus?.label }
            : [],
      finance_remark: dataForm?.finance_remark,
      inf_video_cost: dataForm.inf_video_cost,
      scheduled_date: dataForm.scheduled_date,
      live_date: dataForm.live_date,
      video_link: dataForm.video_link,
    };
    formdata.append("payloadData", JSON.stringify(payloadData));
    // Dispatch action to create video with the payload
    dispatch(clientUpdateVideoData(formdata))
      .unwrap()
      .then((result) => {
        // Handle the response from the API or backend
        if (result.status) {
          // Show success toast and reset the form if the request is successful
          toast.success(result.message, {
            onClose: () => {
            }
          });
          setShowEditModal(false); // Close modal after success
          setDataForm({
            productName: "",
            videoStatus: "",
            tentativeDate: "",
            inf_video_cost: "",
            scheduled_date: "",
            fileName: null,
            finance_remark: "",
            live_date: "", // Reset live_date as well
            video_link: ""
          }); // Reset the form fields
        } else {
          // Show error messages if the request fails
          setErrorData(result.form_err);
          toast.error(result.message);
        }
      })
      .catch((err) => {
        // Handle errors during the request
        toast.error(err.message);
      });
  };

  const cancelDelete = () => {
    setShowEditModal(false);
  };

  return (
    <>

      <Modal
        size="lg"
        backdrop="static"
        keyboard={false}
        show={showEditModal}
        onHide={cancelDelete}
      >
        <Modal.Header closeButton className=" bg-light p-3">
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row g-3">
            <div className="col-lg-6">
              <div>
                <label for="influencer-name" className="form-label">
                  Influencer Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="influencer-name"
                  className="form-control"
                  placeholder="Enter Influencer Name"
                  required=""
                  value={selectedRowsData?.influencer_name}
                  disabled
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div>
                <label for="phone-field" className="form-label">
                  Channel <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="channel-type"
                  value={selectedRowsData?.channel_name || ""}
                  disabled
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div>
                <label for="leads_score-field" className="form-label">
                  Video Type <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="video-type"
                  value={selectedRowsData?.video_type || ""}
                  disabled
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div>
                <label for="phone-field" className="form-label">
                  Video Status <span className="text-danger">*</span>
                </label>
                <Select
                  name="videoStatus"
                  options={videoStatusList}
                  classNamePrefix="select"
                  onChange={(selected) =>
                    handleSelectChange("videoStatus", selected)
                  }
                  value={dataForm.videoStatus}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div>
                <label for="product-name" className="form-label">
                  Product <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="productName"
                  id="video-type"
                  value={dataForm.productName.label}
                  disabled
                />

                <div className="text-danger">
                  {" "}
                  {errors.productName && <> {errors.productName}</>}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div>
                <label for="phone-field" className="form-label">
                  Influencer Video Cost <span className="text-danger">*</span>
                </label>
                <FormattedInput
                  type="text"
                  className="form-control"
                  name="inf_video_cost"
                  placeholder="Enter Subscribers"
                  value={dataForm.inf_final_video_cost}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-lg-6">
              {dataForm?.videoStatus?.value === 2 ? (
                <div>
                  <label for="phone-field" className="form-label">
                    Live Date <span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    id="scheduled_date"
                    name="live_date"
                    className="form-control"
                    value={dataForm.live_date}
                    onChange={handleChange}
                  />
                </div>
              ) : (
                <div>
                  <label for="phone-field" className="form-label">
                    Scheduled Date <span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    id="scheduled_date"
                    name="scheduled_date"
                    className="form-control"
                    value={dataForm.scheduled_date}
                    onChange={handleChange}
                  />
                </div>
              )}
            </div>
            <div className="col-lg-6">
              <div>
                <label for="phone-field" className="form-label">
                  Video Link <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="video_link"
                  name="video_link"
                  placeholder="Enter Video Link..."
                  className="form-control"
                  value={dataForm.video_link}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="col-lg-6">
              <div>
                <label for="phone-field" className="form-label">
                  Upload Invoice {dataForm?.videoStatus?.value === 2 ? (<span className="text-danger">*</span>) : ""}
                </label>
                <input class="form-control" type="file" name="fileName" onChange={handleChange} id="formFile" />
              </div>
            </div>
            <div className="col-lg-6">
              <div>
                <label for="phone-field" className="form-label">
                  Finance Remark  {dataForm?.videoStatus?.value === 2 ? (<span className="text-danger">*</span>) : ""}
                </label>
                <input
                  type="text"
                  id="finance_remark"
                  name="finance_remark"
                  placeholder="Enter Finance Remark..."
                  className="form-control"
                  value={dataForm.finance_remark}
                  onChange={handleChange}
                />
              </div>
            </div>


            <div className="col-lg-12">
              <div>
                <label for="date-field" className="form-label">
                  Tentative Date <span className="text-danger">*</span>
                </label>
                <input
                  type="date"
                  id="date-field"
                  className="form-control"
                  name="tentativeDate"
                  onChange={handleChange}
                  value={dataForm.tentativeDate}
                  disabled
                />
              </div>
            </div>
            <div className="col-lg-12">
              <div>
                <label for="video-cost-field" className="form-label">
                  Video Cost <span className="text-danger">*</span>
                </label>
                <FormattedInputYouTube
                  type="text"
                  className="form-control"
                  name="below_channels.youTube.subscribers"
                  placeholder="Enter Subscribers"
                  value={selectedRowsData?.inf_video_cost}
                  // onChange={handleInputChange}
                  required
                  disabled
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="hstack gap-2 justify-content-end">
            <Button
              variant="danger"
              className="btn btn-light"
              onClick={cancelDelete}
            >
              Close
            </Button>
            <Button
              variant="success"
              className="btn btn-success"
              onClick={handelCreateVideo}
            >
              Submit
            </Button>{" "}
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateVideoDM;
