import React, { useEffect, useState } from "react";
import InvoiceRemarak from "../common/InvoiceRemark";
import InvoiceRemark from "../common/InvoiceRemark";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchInvoiceUniqueData, invoiceDocumentskData, invoiceRemarkData, } from "../../features/invoicesSlice/invoicesSlice";
import { formatDate, formatDateTime } from "../../utils/dateUtils";
import { formatNumber } from "../../utils/formatNumber";
const uploadUrl = process.env.REACT_APP_BACKEND_UPLOADS;


const InvoicesDetails = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const [videoData, setVideoData] = useState([]);

    const [showRemark, setShowRemark] = useState(false)
    const selectedData = location.state;

    console.log("selectedData ==>", selectedData);

    const { invoiceDataDetails, invoiceRemarkDetails, invoiceDocumentsDetails, invoicePaymentData, status, message } = useSelector(
        (state) => state.invoiceFinance
    );

    useEffect(() => {
        if (selectedData?.id) {
            const payload = {
                video_id: selectedData?.id
            };
            dispatch(fetchInvoiceUniqueData(payload))
            dispatch(invoiceRemarkData(payload))
            dispatch(invoiceDocumentskData(payload))
        }
    }, [dispatch, selectedData])

    useEffect(() => {
        setVideoData(invoiceDataDetails)

    }, [invoiceDataDetails])


    const handleRemark = () => {
        setShowRemark(true)
    }

    return <>

        <div className="profile-foreground position-relative mx-n4 mt-n4">
            <div className="profile-wid-bg">
                <img src="../assets/images/profile-bg.jpg" alt="" className="profile-wid-img" />
            </div>
        </div>
        <div className="pt-4 mb-4 mb-lg-3 pb-lg-4 profile-wrapper">
            <div className="row g-4">
                <div className="col-auto">
                    <div className="avatar-lg">
                        <img src="../assets/images/channels4_profile.jpg  " alt="user-img" className="img-thumbnail" />
                    </div>
                </div>
                <div className="col">
                    <div className="p-2">
                        <h3 className="text-white mb-1">Video ID: {videoData?.show_video_id}</h3>
                        <p className="text-white text-opacity-75">
                            <div className="badge rounded-pill bg-success fs-12">{videoData?.show_payment_status}</div>
                        </p>
                        <div className="hstack text-white-50 gap-1">
                            <div className="me-2 hstack gap-3 flex-wrap">
                                <div>Invoice Date: <span className="fw-medium">{formatDate(videoData?.created_at)}
                                </span></div>
                            </div>
                            <div className="me-2">
                                <div>Video Live Date: <span className="fw-medium">{formatDate(videoData?.tentative_date)}</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col-lg-12">
                <div>
                    <div className="d-flex profile-wrapper">
                        <ul className="nav nav-pills animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link fs-14 active" data-bs-toggle="tab" href="#overview-tab" role="tab">
                                    <i className="ri-airplay-fill d-inline-block d-md-none"></i> <span
                                        className="d-none d-md-inline-block">Overview</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link fs-14" data-bs-toggle="tab" href="#invoice" role="tab">
                                    <i className="ri-folder-4-line d-inline-block d-md-none"></i> <span
                                        className="d-none d-md-inline-block">Invoice</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link fs-14" data-bs-toggle="tab" href="#remarks" role="tab">
                                    <i className="ri-price-tag-line d-inline-block d-md-none"></i> <span
                                        className="d-none d-md-inline-block">Remarks</span>
                                </a>
                            </li>


                        </ul>
                    </div>
                    <div className="tab-content pt-4 text-muted">
                        <div className="tab-pane active" id="overview-tab" role="tabpanel">
                            <div className="row">
                                <div className="col-xxl-12">
                                    <div className="card">
                                        <div className="card-body">

                                            <div className="row">
                                                <div className="col-6 col-md-4">
                                                    <div className="d-flex mt-4">
                                                        <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                                            <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                                                <i className="ri-user-2-fill"></i>
                                                            </div>
                                                        </div>
                                                        <div className="flex-grow-1 overflow-hidden">
                                                            <p className="mb-1">Influencer Name :</p>
                                                            <h6 className="text-truncate mb-0">{videoData?.influencer_name}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-4">
                                                    <div className="d-flex mt-4">
                                                        <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                                            <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                                                <i className="ri-global-line"></i>
                                                            </div>
                                                        </div>
                                                        <div className="flex-grow-1 overflow-hidden">
                                                            <p className="mb-1">Client Name :</p>
                                                            <h6 className="text-truncate mb-0">{videoData?.client_name}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-4">
                                                    <div className="d-flex mt-4">
                                                        <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                                            <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                                                <i className="ri-user-2-fill"></i>
                                                            </div>
                                                        </div>
                                                        <div className="flex-grow-1 overflow-hidden">
                                                            <p className="mb-1">Video Live Status :</p>
                                                            <h6 className="text-truncate mb-0">{videoData?.show_video_status}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="col-6 col-md-4">
                                                    <div className="d-flex mt-4">
                                                        <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                                            <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                                                <i className="ri-global-line"></i>
                                                            </div>
                                                        </div>
                                                        <div className="flex-grow-1 overflow-hidden">
                                                            <p className="mb-1">Invoice No :</p>
                                                            <h6 className="text-truncate mb-0">{videoData?.influencer_name}</h6>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className="col-6 col-md-4">
                                                    <div className="d-flex mt-4">
                                                        <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                                            <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                                                <i className="ri-user-2-fill"></i>
                                                            </div>
                                                        </div>
                                                        <div className="flex-grow-1 overflow-hidden">
                                                            <p className="mb-1">Payment Status :</p>
                                                            <h6 className="text-truncate mb-0">{videoData?.show_payment_status}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-4">
                                                    <div className="d-flex mt-4">
                                                        <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                                            <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                                                <i className="ri-global-line"></i>
                                                            </div>
                                                        </div>
                                                        <div className="flex-grow-1 overflow-hidden">
                                                            <p className="mb-1">Last Video Cost :</p>
                                                            <h6 className="text-truncate mb-0">{formatNumber(videoData?.last_video_cost)}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-4">
                                                    <div className="d-flex mt-4">
                                                        <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                                            <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                                                <i className="ri-user-2-fill"></i>
                                                            </div>
                                                        </div>
                                                        <div className="flex-grow-1 overflow-hidden">
                                                            <p className="mb-1">Original Video Cost :</p>
                                                            <h6 className="text-truncate mb-0">{formatNumber(videoData?.inf_video_cost)}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-4">
                                                    <div className="d-flex mt-4">
                                                        <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                                            <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                                                <i className="ri-global-line"></i>
                                                            </div>
                                                        </div>
                                                        <div className="flex-grow-1 overflow-hidden">
                                                            <p className="mb-1">Taxable Amount :</p>
                                                            <h6 className="text-truncate mb-0">{formatNumber(videoData?.taxable_amt)}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-4">
                                                    <div className="d-flex mt-4">
                                                        <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                                            <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                                                <i className="ri-user-2-fill"></i>
                                                            </div>
                                                        </div>
                                                        <div className="flex-grow-1 overflow-hidden">
                                                            <p className="mb-1">Channel Type :</p>
                                                            <h6 className="text-truncate mb-0">{videoData?.channel_name}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-4">
                                                    <div className="d-flex mt-4">
                                                        <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                                            <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                                                <i className="ri-user-2-fill"></i>
                                                            </div>
                                                        </div>
                                                        <div className="flex-grow-1 overflow-hidden">
                                                            <p className="mb-1">Video Type :</p>
                                                            <h6 className="text-truncate mb-0">{videoData?.video_type}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-4">
                                                    <div className="d-flex mt-4">
                                                        <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                                            <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                                                <i className="ri-global-line"></i>
                                                            </div>
                                                        </div>
                                                        <div className="flex-grow-1 overflow-hidden">
                                                            <p className="mb-1">Live Link :</p>
                                                            <h6 className="text-truncate mb-0">
                                                                <a href={videoData?.video_link} target="_blank" rel="noopener noreferrer">
                                                                    {videoData?.video_link}
                                                                </a>
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-4">
                                                    <div className="d-flex mt-4">
                                                        <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                                            <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                                                <i className="ri-global-line"></i>
                                                            </div>
                                                        </div>
                                                        <div className="flex-grow-1 overflow-hidden">
                                                            <p className="mb-1">PAN No :</p>
                                                            <h6 className="text-truncate mb-0">{videoData?.pan_no}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-4">
                                                    <div className="d-flex mt-4">
                                                        <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                                            <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                                                <i className="ri-global-line"></i>
                                                            </div>
                                                        </div>
                                                        <div className="flex-grow-1 overflow-hidden">
                                                            <p className="mb-1">GST No :</p>
                                                            <h6 className="text-truncate mb-0">{videoData?.gst_no}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-4">
                                                    <div className="d-flex mt-4">
                                                        <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                                            <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                                                <i className="ri-global-line"></i>
                                                            </div>
                                                        </div>
                                                        <div className="flex-grow-1 overflow-hidden">
                                                            <p className="mb-1">Bank Name :</p>
                                                            <h6 className="text-truncate mb-0">{videoData?.bank_name}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-4">
                                                    <div className="d-flex mt-4">
                                                        <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                                            <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                                                <i className="ri-global-line"></i>
                                                            </div>
                                                        </div>
                                                        <div className="flex-grow-1 overflow-hidden">
                                                            <p className="mb-1">Account No :</p>
                                                            <h6 className="text-truncate mb-0">{videoData?.account_no}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-4">
                                                    <div className="d-flex mt-4">
                                                        <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                                            <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                                                <i className="ri-global-line"></i>
                                                            </div>
                                                        </div>
                                                        <div className="flex-grow-1 overflow-hidden">
                                                            <p className="mb-1">IFSC Code :</p>
                                                            <h6 className="text-truncate mb-0">{videoData?.ifsc_code}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card">
                                                <div className="card-header align-items-center d-flex">
                                                    <h4 className="card-title mb-0  me-2">Transactions Amount</h4>
                                                </div>
                                                <div className="card-body">
                                                    <div className="border-top border-top-dashed">
                                                        <div className="row gy-3">
                                                            <div className="table-responsive">
                                                                <table className="table align-middle mb-0">
                                                                    <thead className="table-light">
                                                                        <tr>
                                                                            <th scope="col">Transaction ID</th>
                                                                            <th scope="col">Amount Paid</th>
                                                                            <th scope="col">Amount Paid By</th>
                                                                            <th scope="col">Payment Status</th>
                                                                            <th scope="col">Total Amount</th>
                                                                            <th scope="col">Remaining Amount</th>
                                                                            <th scope="col">Payment Date</th>
                                                                            {/* <th scope="col">Invoice Amount</th> */}
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>

                                                                        {invoicePaymentData?.map((item, index) => (
                                                                            <tr key={item.id}>
                                                                                <td>{item.invoice_no}</td>
                                                                                <td>{formatNumber(item.paid_amt)}</td>
                                                                                <td>{item.created_by_name}</td> {/* Update dynamically if needed */}
                                                                                <td>{item.show_payment_status}</td>  {/* Update dynamically if needed */}
                                                                                <td>{formatNumber(videoData?.inf_video_cost)}</td>  {/* Update dynamically if needed */}
                                                                                <td>NA</td> {/* Update dynamically if needed */}
                                                                                <td>{formatDate(item.paid_date)}</td>
                                                                                {/* <td>{item.taxable_amt}</td> */}
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="remarks" role="tabpanel">
                            <div class="card">
                                <div class="card-body">
                                    <div class="d-flex align-items-center mb-4">
                                        <h5 class="card-title flex-grow-1 mb-0">Remarks</h5>
                                        <div class="flex-shrink-0">

                                            <button onClick={handleRemark} class="btn btn-success"><i
                                                class="ri-add-line align-bottom me-1"></i> Add
                                                Remarks</button>
                                        </div>
                                    </div>

                                    <div className="acitivity-timeline">
                                        {invoiceRemarkDetails?.map((data, index) => (
                                            <div className="acitivity-item d-flex" key={index}>
                                                <div className="flex-shrink-0 avatar-xs acitivity-avatar">
                                                    <div className={`avatar-title ${data?.role_id === 8 ? 'bg-success-subtle text-success' : data?.role_id === 5 ? 'bg-danger-subtle text-danger' : ''} rounded-circle`}>
                                                        <i className="ri-user-3-fill"></i>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <h6 className="mb-1">
                                                        From: {data?.created_by_name}{" "}
                                                        {/* <span className="badge bg-primary-subtle text-primary align-middle">New</span> */}
                                                    </h6>
                                                    <p className="text-muted mb-2">{data?.remark}</p>
                                                    <small className="mb-0 text-muted">{formatDate(data?.created_at)}</small>
                                                    <ul className="list-inline hstack gap-2 mt-2 remarks-action">
                                                        {/* Add any action buttons or additional info here */}
                                                    </ul>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                </div>

                            </div>
                        </div>

                        <div class="tab-pane fade" id="invoice" role="tabpanel">
                            <div class="card">
                                <div class="card-body">
                                    <div class="d-flex align-items-center mb-4">
                                        <h5 class="card-title flex-grow-1 mb-0">Documents</h5>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="table-responsive">
                                                <table class="table table-borderless align-middle mb-0">
                                                    <thead class="table-light">
                                                        <tr>
                                                            <th scope="col">File Name</th>
                                                            {/* <th scope="col">Type</th>
                                                            <th scope="col">Size</th> */}
                                                            <th scope="col">Upload Date</th>
                                                            <th scope="col">Uploaded by</th>
                                                            {/* <th>Payment Status</th> */}
                                                            {/* <th scope="col">Action</th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {invoiceDocumentsDetails?.map((data, index) => (
                                                            <tr>
                                                                <td>
                                                                    <div class="d-flex align-items-center">
                                                                        <div class="avatar-sm">
                                                                            <div class="avatar-title bg-primary-subtle text-primary rounded fs-20">
                                                                                <i class="ri-file-fill"></i>
                                                                            </div>
                                                                        </div>
                                                                        <div class="ms-3 flex-grow-1">
                                                                            <h6 class="fs-15 mb-0">
                                                                                <a href={data?.invoices ? `${uploadUrl}/${data.invoices}` : "#"} target="_blank">{data?.invoices}</a>
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                {/* <td>pdf File</td>
                                                                <td>4.57 MB</td> */}
                                                                <td>{formatDate(data?.created_at)}</td>
                                                                <td>{data?.created_by_name}</td>
                                                                {/* <td><span class="badge bg-info">{data?.payment_status || "N/A"}</span></td> */}
                                                                {/* <td>
                                                                    <ul class="list-inline hstack mb-0">
                                                                        <li class="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover"
                                                                            data-bs-placement="top" aria-label="Download"
                                                                            data-bs-original-title="Download">
                                                                            <button class="btn btn-sm btn-soft-info" data-edit-id="14"><i
                                                                                class="ri-download-2-fill align-bottom fs-16"></i></button>

                                                                        </li>
                                                                        <li class="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover"
                                                                            data-bs-placement="top" aria-label="Delete" data-bs-original-title="Delete">
                                                                            <button class="btn btn-sm btn-soft-danger" data-bs-toggle="modal"
                                                                                data-bs-target="#deleteDocumentModal" data-remove-id="15"><i
                                                                                    class="ri-delete-bin-5-fill align-bottom fs-16"></i></button>

                                                                        </li>

                                                                    </ul>
                                                                </td> */}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <InvoiceRemark showRemark={showRemark} setShowRemark={setShowRemark} selectedDataId={selectedData?.id} />

    </>;
};

export default InvoicesDetails;
