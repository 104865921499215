import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../api/axiosInstance';

// Thunks for making async requests
export const clientInfluencerRequest = createAsyncThunk(
  'influencerRequest/clientInfluencerRequest',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/client/influencer_request', formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


// Thunks for making async requests
export const clientCreateVideo = createAsyncThunk(
  'influencerRequest/clientCreateVideo',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/video_script/add_video_script', formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


export const clientUpdateVideoData = createAsyncThunk(
  'influencerRequest/clientUpdateVideoData',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/video_script/update_video_script', formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const clientUpdateInfluencerRequest = createAsyncThunk(
  'influencerRequest/clientUpdateInfluencerRequest',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/client/influencer_request_update', formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchClientRequestData = createAsyncThunk(
  'influencerRequest/fetchClientRequestData',
  async ({ client_id, page = 1, limit = 10 }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/client/my_request', {
        client_id,
        page,
        limit
      });
      return { client_id, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchClientDeleteData = createAsyncThunk(
  'influencerRequest/fetchClientDeleteData',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/client/request_status_update', formData);
      return { data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// Initial state with pagination
const initialClientState = {
  data: [],
  pagination: {
    limit: 10,
    page: 1,
    totalItems: 0,
    totalPages: 0
  }
};

// Slice definition
const commonSlice = createSlice({
  name: 'influencerRequest',
  initialState: {
    clientDataRequest: { ...initialClientState },
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(clientInfluencerRequest.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(clientInfluencerRequest.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(clientInfluencerRequest.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload ? action.payload.message : action.error.message;
      })

      .addCase(clientCreateVideo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(clientCreateVideo.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(clientCreateVideo.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload ? action.payload.message : action.error.message;
      })

      .addCase(clientUpdateVideoData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(clientUpdateVideoData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(clientUpdateVideoData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload ? action.payload.message : action.error.message;
      })

      .addCase(clientUpdateInfluencerRequest.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(clientUpdateInfluencerRequest.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(clientUpdateInfluencerRequest.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload ? action.payload.message : action.error.message;
      })
      .addCase(fetchClientRequestData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchClientRequestData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.clientDataRequest = {
          data: action.payload.data.data,
          pagination: action.payload.data.pagination
        };
      })
      .addCase(fetchClientRequestData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload ? action.payload.message : action.error.message;
      })
      .addCase(fetchClientDeleteData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchClientDeleteData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchClientDeleteData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload ? action.payload.message : action.error.message;
      });
  }
});

export default commonSlice.reducer;
